


































export default({
  name: 'HelloWorld',
  props: {
    msg: String,
  },
});
